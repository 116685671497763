$(document).on('turbolinks:load', function(){
  $('#fileupload').fileupload({
    dataType: 'json',
    maxFileSize: 1000000000,
    done: function (e, data) {
        if (!data.result) {
            $('#loader-bg').delay(200).fadeOut(300);
            alert("アップデートに失敗しました。\nファイルの容量、ファイル名の長さは問題ないですか？\n実行ファイル(.jsや.exe)はアップロードできません。\nご確認ください。");
        } else {
          $.each(data.result.files, function (index, file) {
              $('#filename').text("アップしたファイル：" + file.name);
              if ($('#file_box_doc_file_id').length > 0) {
                $('#file_box_doc_file_id').val(file.id);
              } else if ($('#notice_doc_file_id').length > 0) {
                $('#notice_doc_file_id').val(file.id);
              } else if ($('#campaign_doc_file_id').length > 0) {
                $('#campaign_doc_file_id').val(file.id);
              }
              $('#filename').append('<a data-confirm="アップロードが取り消されますが、よろしいですか？" class="btn btn-dark ml-3" data-remote="true" rel="nofollow" data-method="delete" href="' + file.deleteUrl + '" >取消</a>');
              $('#loader-bg').delay(200).fadeOut(300);
          });
        }
    },
    progressall: function (e, data) {
      var progress = parseInt(data.loaded / data.total * 100, 10);
      $('#loader #progress .bar').css(
        'width',
        progress + '%'
      );
    },
    fail: function (e, data) {
        $('#loader-bg').delay(200).fadeOut(300);
        alert("アップデートに失敗しました。\nファイルの容量、ファイル名の長さは問題ないですか？\n実行ファイル(.jsや.exe)はアップロードできません。\nご確認ください。");
    }
  }).bind('fileuploadstart', function (e) {
      $('#loader-bg').css('display','block');
  });

  $('#set_none_doc_file').on( 'click', function() {
      $('#filename').text("");
      $('#before_filename').text("");
      $('input[id$=_doc_file_id]').val("");
  });
});

