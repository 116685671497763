require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context('../images', true)

import './bootstrap_custom.js'
import './date.js'
import './color.js'
import 'blueimp-file-upload/js/jquery.fileupload'
import './fileup.js'
import '@fortawesome/fontawesome-free/js/all'

import '../stylesheets/application.scss'

$(document).on('turbolinks:load', function() {
    $('#searchbox').on('shown.bs.collapse', function () {
        $('#q_doc_cont_all').focus();
    });
});
